import { IsDefined } from '@utils/js-ts';
import CURRENT_POSITIONS from './current_positions.json';
import PORTFOLIO from './portfolio.json';
import EXCHANGES from './exchanges.json';
const getCurrentPositions = (skip, take, sortByPnl = 'asc') => {
    let currentPositions = CURRENT_POSITIONS;
    if (sortByPnl === 'asc') {
        currentPositions = currentPositions.sort((a, b) => a.pnl - b.pnl);
    }
    else if (sortByPnl === 'desc') {
        currentPositions = currentPositions.sort((a, b) => b.pnl - a.pnl);
    }
    if (IsDefined(skip) && IsDefined(take)) {
        currentPositions = currentPositions.slice(Number(skip), Number(skip) + Number(take));
    }
    return {
        count: CURRENT_POSITIONS.length,
        data: currentPositions,
    };
};
const getPositionsHistory = (skip, take) => {
    let history = CURRENT_POSITIONS;
    const months = {};
    history = history.sort((a, b) => new Date(a.opened).getTime() - new Date(b.opened).getTime());
    history.forEach(position => {
        try {
            const positionsMonth = new Date(position.opened).getMonth();
            if (!IsDefined(months[positionsMonth])) {
                months[positionsMonth] = {
                    month: positionsMonth,
                    count: 1,
                    positions: [position],
                };
            }
            else {
                months[positionsMonth].count += 1;
                months[positionsMonth].positions.push(position);
            }
        }
        catch (ex) {
            console.warn(ex);
        }
    });
    const result = {};
    if (IsDefined(skip) && IsDefined(take)) {
        history = history.slice(Number(skip), Number(skip) + Number(take));
        history.forEach(position => {
            try {
                const positionsMonth = new Date(position.opened).getMonth();
                if (!IsDefined(result[positionsMonth])) {
                    result[positionsMonth] = {
                        month: positionsMonth,
                        count: months[positionsMonth].count,
                        positions: [position],
                    };
                }
                else {
                    result[positionsMonth].positions.push(position);
                }
            }
            catch (ex) {
                console.warn(ex);
            }
        });
    }
    return {
        count: CURRENT_POSITIONS.length,
        data: result,
    };
};
const getPortfolio = () => {
    const portfolio = PORTFOLIO;
    return Object.assign(Object.assign({}, portfolio), { exchange: EXCHANGES[0] });
};
const PortfoliosCount = 9;
const getProfilePortfolios = () => {
    const response = [];
    for (let i = 0; i < PortfoliosCount; i += 1) {
        response.push(Object.assign(Object.assign({}, PORTFOLIO), { id: i + 1, name: `id:${i + 1} ${PORTFOLIO.name}`, exchange: EXCHANGES[0] }));
    }
    return response;
};
export { getCurrentPositions, getPositionsHistory, getPortfolio, getProfilePortfolios, };
